<template>
  <div class="container">
    <van-tabs class="tabs common-card-tabs" background="var(--app-bg-color)" type="card" v-model:active="active" sticky swipeable animated @change="changeTab">
      <van-tab class="tab-item" v-for="ranking in rankingTabs" :title="ranking.title" :key="ranking.search.type">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <div class="ranking-list">
            <div class="ranking-top">
              <div class="top-item" v-for="item in ranking.topList" :key="item.rank" :class="`top${item.rank}`">
                <img class="top-ranking" :src="`/images/game-ranking/ranking${item.rank}.png`" />
                <div class="top-avatar">
                  <img :src="item.steam_player.avatarfull" />
                </div>
                <div class="top-name">{{ item.steam_player.personaname }}</div>
                <div class="top-content">
                  <div class="data-item" @click="showTopPlayer(item)">{{ item.layers }}<van-icon class="top-view" name="eye-o" size="16" /></div>
                </div>
              </div>
            </div>
            <van-list v-model:loading="isLoading" :finished="ranking.finished" finished-text="没有更多了" @load="loadData">
              <div class="card" v-for="item in ranking.list" :key="item.rank">
                <div class="ranking">{{ item.rank }}</div>
                <div class="avatar">
                  <img :src="item.steam_player.avatarfull" />
                </div>
                <div class="card-right">
                  <div class="name">{{ item.steam_player.personaname }}</div>
                  <div class="card-content">
                    <div class="data-item" style="width: 30%">
                      <div class="data-value">{{ item.layers }}</div>
                      <div class="data-label">深渊层数</div>
                    </div>
                    <div class="data-item other-data" style="width: 70%">
                      <div class="data-value">
                        {{ item.other }}
                      </div>
                      <div class="data-label" @click="showOtherTips"><span>额外数据</span><van-icon name="warning-o" color="#1989fa" size="15" /></div>
                    </div>
                  </div>
                </div>
              </div>
            </van-list>
          </div>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>

    <div class="right-fixed">
      <div class="item" @click="showPoster">我的排名</div>
    </div>

    <van-dialog v-model:show="showTopPlayerModel" confirm-button-text="关闭" :title="topPlayer.steam_player.personaname">
      <div class="card-content">
        <div class="data-item" style="width: 40%">
          <div class="data-value">{{ topPlayer.layers }}</div>
          <div class="data-label">深渊层数</div>
        </div>
        <div class="data-item other-data" style="width: 60%">
          <div class="data-value">{{ topPlayer.other }}</div>
          <div class="data-label">额外数据<i class="weui-icon-info-circle tips"></i></div>
        </div>
      </div>
    </van-dialog>

    <van-dialog class="poster-dialog" v-model:show="showPosterModel" confirm-button-text="关闭" title="您的排名">
      <img class="poster-view" :src="posterImage" />
    </van-dialog>

    <div class="poster" id="poster-box" v-show="showPosterBox">
      <div class="poster-badge">
        <div class="poster-rank">{{ posterPlayer.rank }}</div>
        <img v-if="posterPlayer.rank <= 10" src="/images/game-ranking/07.png" />
        <img v-else-if="posterPlayer.rank <= 50" src="/images/game-ranking/06.png" />
        <img v-else-if="posterPlayer.rank <= 100" src="/images/game-ranking/05.png" />
        <img v-else-if="posterPlayer.rank <= 200" src="/images/game-ranking/04.png" />
        <img v-else-if="posterPlayer.rank <= 500" src="/images/game-ranking/03.png" />
        <img v-else-if="posterPlayer.rank <= 1000" src="/images/game-ranking/02.png" />
      </div>
      <div class="poster-avatar"><img :src="posterPlayer.steam_player.avatarfull" /></div>
      <div class="poster-name">{{ posterPlayer.steam_player.personaname }}</div>
      <div class="qrcode">
        <img src="/images/common/subscribe-qrcode.jpg" />
      </div>
      <div class="remark">
        <p>长按图片保存到相册</p>
        <p>关注月之底公众号领福利</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Tui6Ranking',
}
</script>
<script setup>
import { Tab, Tabs, Icon, List, Dialog, Notify, Toast, PullRefresh } from 'vant'
import html2canvas from 'html2canvas'
import { ref, onMounted, reactive, nextTick } from 'vue'
import { tui6Ranking } from '@/api/gameRanking'
import { useStore } from 'vuex'

const VanTab = Tab
const VanTabs = Tabs
const VanIcon = Icon
const VanList = List
const VanPullRefresh = PullRefresh
const VanDialog = Dialog.Component
const store = useStore()
const active = ref(0)
const showTopPlayerModel = ref(false)
const isLoading = ref(false)
const topPlayer = ref({ steam_player: {} })
const rankingTabs = reactive([
  { title: '深渊多人榜', finished: false, search: { type: 1, page: 0, season: 5 }, topList: [], list: [], user: null },
  { title: '深渊单人榜', finished: false, search: { type: 2, page: 0, season: 5 }, topList: [], list: [], user: null },
])
const showPosterModel = ref(false)
const posterImage = ref(null)
const showPosterBox = ref(false)
const posterPlayer = ref({ rank: 0, steam_player: { avatarfull: '', personaname: '' } })

onMounted(options => {
  store.dispatch('user/getInfo')
  store.dispatch('player/getInfo')
})

const changeTab = () => {
  //切换时数据为空则默认去获取第一页数据
  let activeRanking = rankingTabs[active.value]
  if (!activeRanking.list.length) {
    rankingTabs[active.value].finished = false
    rankingTabs[active.value].search.page = 0
    loadData()
  }
}

const onRefresh = () => {
  rankingTabs[active.value].finished = false
  isLoading.value = true
  rankingTabs[active.value].search.page = 0
  loadData()
}

const loadData = async () => {
  // if (rankingTabs[active.value].finished) {
  //   return false
  // }

  rankingTabs[active.value].search.page++
  let activeRanking = rankingTabs[active.value]

  if (activeRanking.search.page == 1) {
    rankingTabs[active.value].topList = []
    rankingTabs[active.value].list = []
  }

  try {
    let res = await tui6Ranking(activeRanking.search)
    let list = res.data.list
    let type = res.data.type
    let activeIndex = type - 1
    if (!res.data.list.length || res.data.page >= res.data.pageMax) {
      rankingTabs[activeIndex].finished = true
      rankingTabs[activeIndex].search.page = res.data.pageMax
    }

    if (res.data.user) {
      rankingTabs[activeIndex].user = res.data.user
    }

    if (list) {
      //拆分出前三名放到topList
      if (res.data.page == 1) {
        list.forEach(item => {
          item.other = item.layers < 100 ? item.kill_amounts : item.candy_hp > 1 ? parseInt(item.candy_hp) : item.candy_hp
          if (item.rank <= 3) {
            rankingTabs[activeIndex].topList.push(item)
          } else {
            rankingTabs[activeIndex].list.push(item)
          }
        })
      } else {
        list.forEach(item => {
          item.other = item.layers < 100 ? item.kill_amounts : item.candy_hp > 1 ? parseInt(item.candy_hp) : item.candy_hp
          rankingTabs[activeIndex].list.push(item)
        })
      }
    }

    isLoading.value = false
  } catch (err) {
    isLoading.value = false
    rankingTabs[active.value].finished = true
  }
}

const showTopPlayer = data => {
  topPlayer.value = data
  showTopPlayerModel.value = true
}

const showOtherTips = () => {
  Notify({
    message: '此数值代表无尽击杀数',
    //  message: '当层数低于100层时，此数值代表无尽击杀数，否则代表总共打掉年兽的血条数',
    color: '#d2d8e8',
    background: '#2d2d35',
  })
}

const showPoster = () => {
  if (rankingTabs[active.value].user) {
    posterPlayer.value = rankingTabs[active.value].user
  }

  if (!posterPlayer.value.rank) {
    Toast('您当前没有排名，请继续努力哟~')
    return false
  }

  Toast.loading({
    forbidClick: true,
    duration: 60000,
  })

  showPosterBox.value = true

  nextTick(() => {
    const domObj = document.querySelector('#poster-box')
    html2canvas(domObj, {
      useCORS: true,
      backgroundColor: '#452d12',
      logging: false,
      scale: 2,
    }).then(canvas => {
      Toast.clear()
      showPosterBox.value = false
      let dataURL = canvas.toDataURL('image/png')
      posterImage.value = dataURL
      showPosterModel.value = true
    })
  })
}
</script>
<style lang="scss" scoped>
.ranking-list {
  display: flex;
  flex-flow: column;
  align-items: center;
}
.card {
  box-sizing: border-box;
  margin-bottom: 10px;
  width: 90vw;
  padding: 10px;
  display: flex;
  border-radius: 10px;
  background: #2d2d35;
  align-items: center;
}

.ranking {
  flex-shrink: 0;
  width: 32px;
  color: #d2d8e8;
}

.avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 2px solid #fff;
  margin: 0 10px;
  flex-shrink: 0;
}

.avatar img {
  border-radius: 50%;
  width: 100%;
}

.card-right {
  width: 100%;
  display: flex;
  flex-flow: column;
  overflow: hidden;
}

.name {
  height: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  line-height: 100%;
  color: #fff;
}

.card-content {
  padding-top: 5px;
  display: flex;
  justify-content: space-around;
  color: #fff;
  flex-wrap: wrap;
  align-items: center;
}

.card-content .data-item {
  text-align: center;
}

.card-content .data-label {
  font-size: 13px;
  color: #7c8197;
}

.card-content .data-value {
  font-size: 14px;
  font-weight: 500;
  color: #d2d8e8;
}
.ranking-top {
  width: 90vw;
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
}

.top-item {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 33.3vw;
}

.top2,
.top3 {
  margin-top: 40px;
}

.top1 {
  order: 1;
}

.top2 {
  order: 0;
}

.top3 {
  order: 2;
}

.top-ranking {
  width: 50px;
  margin-bottom: 10px;
}

.top2 .top-ranking,
.top3 .top-ranking {
  width: 45px;
}

.top-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid #fff;
  margin: 0 10px;
  flex-shrink: 0;
  position: relative;
}

.top2 .top-avatar,
.top3 .top-avatar {
  width: 55px;
  height: 55px;
}

.top-avatar img {
  border-radius: 50%;
  width: 100%;
}

.top-name {
  text-align: center;
  margin-top: 10px;
  width: 100%;
  height: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  line-height: 100%;
  color: #fff;
}

.top-content {
  width: 100%;
  text-align: center;
  display: flex;
  flex-flow: column;
  margin-top: 3px;
}

.data-item {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #d2d8e8;
  flex-shrink: 0;
}

.top-view {
  color: #07c160;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-left: 5px;
}

.poster {
  position: absolute;
  z-index: -999;
  background: url('/images/game-ranking/poster-bg.jpg') no-repeat;
  background-size: cover;
  width: 320px;
  height: 70vh;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.poster-view {
  vertical-align: bottom;
  width: 320px;
  height: 70vh;
}

.poster .poster-avatar {
  margin-top: 10px;
  border: 3px solid #fbcc7e;
  border-radius: 50%;
  overflow: hidden;
  width: 60px;
  height: 60px;
}

.poster .poster-avatar img {
  width: 60px;
  height: 60px;
}

.poster .poster-name {
  color: #e99030;
  font-size: 16px;
  height: 16px;
  margin-top: 10px;
}

.poster-badge {
  margin-top: 80px;
  position: relative;
  z-index: 1;
}
.poster-badge img {
  height: 100px;
}

.poster-rank {
  position: absolute;
  bottom: 10px;
  font-size: 20px;
  text-align: center;
  width: 100%;
  color: #e99030;
  z-index: 2;
}
.poster .qrcode {
  margin-top: 15px;
  width: 130px;
  height: 130px;
}
.poster .qrcode img {
  width: 100%;
  height: 100%;
}

.poster .remark {
  color: #b0b4c9;
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
}
.poster .remark p {
  margin: 0;
}

.right-fixed {
  position: fixed;
  bottom: 15%;
  right: 0;
  z-index: 9;
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  background: var(--app-button-bg-color);
  color: #fff;
  padding: 3px;
  font-size: 15px;
  text-align: center;
  border-radius: 50%;
}
</style>
